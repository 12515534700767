import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
// MATERIAL UI
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
// PAGES
import Loader from "./pages/Loader";
import Error from "./pages/Error";
import PageInicio from "./pages/PageInicio";
// Servicios
import { firestore } from "./firebase";

const DefaultTheme = createMuiTheme({
  palette: {
    text: {
      default: "#fff",
    },
  },
});

function App() {
  const [configurations, setConfigurations] = useState(null);
  const [theme, setTheme] = useState(DefaultTheme);
  const [load, setLoad] = useState(true);

  //Tema
  useEffect(() => {
    const onConfiguration = firestore
      .collection("config")
      .doc("configurations")
      .onSnapshot((docUC) => {
        if (docUC.exists) {
          let configData = docUC.data();
          setConfigurations(configData);

          const theme = createMuiTheme({
            palette: {
              primary: {
                main: configData.primaryColor,
              },
              secondary: {
                main: configData.secondaryColor,
              },
              bkColor: configData.colorBk,
              accept: configData.darkprimaryColor,
              primaryTextColor: configData.primaryTextColor,
            },
            overrides: {
              MuiCssBaseline: {
                "@global": {
                  body: {
                    "--primaryColor": configData.primaryColor,
                    "--secondaryColor": configData.secondaryColor,
                    "--darkprimaryColor": configData.darkprimaryColor,
                    "--lightprimaryColor": configData.lightprimaryColor,
                    "--textColor": configData.textColor,
                    "--primaryTextColor": configData.primaryTextColor,
                    "--secondaryTextColor": configData.secondaryTextColor,
                    "--dividerColor": configData.dividerColor,
                    "--colorBk": configData.colorBk,
                    "--regular": ["RobotoR"],
                    "--medium": ["RobotoM"],
                    "--bold": ["RobotoB"],
                    "--light": ["RobotoL"],
                    "--bkMovil": `url(${configData.imagenBkMovil})`,
                    "--bkTotem": `url(${configData.imagenBkTotem})`,
                    "--bkEscritorio": `url(${configData.imagenBkEscritorio})`,
                    fontFamily: ["RobotoR"],
                    backgroundColor: configData.colorBk,
                    // backgroundImage: `url(${configData.imagenBk})`,
                    //backgroundImage: `url(${configData.imagenBkMovil}), linear-gradient(0deg, rgba(255,255,255,0) 33%, rgba(0,5,22,1) 100%)`,
                  },
                },
              },
            },
          });

          setTheme(theme);
          setLoad(false);
        } else {
          setConfigurations(null);
          setLoad(false);
        }
      });
    return () => {
      onConfiguration();
    };
  }, []);

  //Fin Tema

  if (load) {
    return <Loader />;
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {configurations && configurations.on === false ? (
          <Error />
        ) : (
          <PageInicio configurations={configurations}/>
        )}
      </ThemeProvider>

      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
