const styles = (theme) => ({
  gridForm: {
    width: "60%",
    margin: "3% auto 30px auto",
    // backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: "25px",
    padding: "10px 40px 10px 40px",
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "10% auto 30px auto",
    },
  },
  form: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "0 auto",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: "5px 30px",
    fontFamily: ["RobotoR"],
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    borderRadius: "50px",
    "&:hover": {
      backgroundColor: "#fff",
      color: theme.palette.secondary.main,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#fff",
      color: theme.palette.secondary.main,
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
  // submit: {
  //   margin: theme.spacing(3, 0, 2),
  //   fontFamily: ["ProM"],
  //   backgroundColor: theme.palette.secondary.main,
  //   color: "fff",
  //   borderRadius: "50px",
  //   "&:hover": {
  //     backgroundColor: "#fff",
  //     color: theme.palette.secondary.main,
  //     boxShadow: "none",
  //   },
  //   "&:active": {
  //     boxShadow: "none",
  //     backgroundColor: "#fff",
  //     color: theme.palette.secondary.main,
  //   },
  //   "&:focus": {
  //     boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  //   },
  // },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.secondary.main,
  },
});

export default styles;
