import React from "react";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";

const override = css`
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 49%;
`;

function Loader() {
  return <HashLoader color={"#4a90e2"} css={override} size={30} />;
}

export default Loader;
