import React, { useState, useEffect } from "react";
//Componentes
import Modal1 from "./Modal1";
import Galeria from "./GaleriaGrid";
import Contacto from "./Contactenos";
//Boostrap
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
//Estilos
import styles from "./styles";
import { withStyles } from "@material-ui/styles";
import "./PageInicio.css";
import Aos from "aos";
import "aos/dist/aos.css";
//Media
import Banner from "../../assets/images/banner.png";
import Eventos from "../../assets/images/eventos.png";
import Alquiler from "../../assets/images/alquiler.png";
import Servicios from "../../assets/images/servicios.png";
import Somos from "../../assets/images/somos.png";
import Arriba from "../../assets/images/arriba.png";
import Abajo from "../../assets/images/abajo.png";
import VideoImg from "../../assets/images/video.png";
import EventoI from "../../assets/images/evento-i.png";
import AlquilerI from "../../assets/images/alquiler-i.png";
import ServiciosI from "../../assets/images/servicios-i.png";
import EventosTitle from "../../assets/images/eventos-title.png";
import AlquilerTitle from "../../assets/images/alquiler-title.png";
import ServiciosTitle from "../../assets/images/servicios-title.png";
import ImagenMedia from "../../assets/images/imagen-media.png";
import FooterImg from "../../assets/images/footer1.png";
import Instagram from "../../assets/images/insta.png";
import Facebook from "../../assets/images/face.png";
import IconEvento from "../../assets/images/evento.png";
import IconHumano from "../../assets/images/humano.png";
import IconFiesta from "../../assets/images/fiesta.png";

//Servicios

function PageInicio(props) {
  const { configurations } = props;
  const [open1, setOpen1] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const close1 = () => {
    setOpen1(false);
  };

  // const opens1 = () => {
  //   setOpen1(true);
  // };

  return (
    <>
      <Navbar
        expand="lg"
        fixed="top"
        sticky="top"
        className="menuLink"
        variant="dark"
      >
        <Container fluid>
          <Navbar.Brand href="#Home" className="menuMovil">
            <img src={configurations.logo} alt="Logo" className="logoIni" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end" style={{ width: "100%" }}>
              <Nav.Link href="#Eventos" className="itemM">
                EVENTOS
              </Nav.Link>
              <Nav.Link href="#Alquiler" className="itemM alquilerEscritorio">
                ALQUILER
              </Nav.Link>
              <Nav.Link href="#AlquilerMovil" className="itemM alquilerMovil">
                ALQUILER
              </Nav.Link>
              <Nav.Link href="#Servicios" className="itemM">
                SERVICIOS
              </Nav.Link>
              <Nav.Link href="#Contactenos" className="itemM">
                CONTACTENOS
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="container-fluid" id="Home">
        <div className="row">
          <div className="col-12 sinP">
            <img
              src={Banner}
              alt="Banner"
              className="img-thumbnail mx-auto d-block sinP"
            />
          </div>
        </div>
      </div>
      <div className="container-fluid index1">
        <div className="row">
          <div className="col-12 col-md-6" data-aos="fade-right">
            <div className="row justify-content-center hacemos">
              <h2 className="titlesh2">LO QUE HACEMOS</h2> <br /> <br />
              <div className="col-4">
                <a href="#Eventos">
                  <img
                    src={Eventos}
                    alt="Eventos"
                    className="img-thumbnail mx-auto d-block iconItem"
                  />
                </a>
              </div>
              <div className="col-4 alquilerEscritorio">
                <a href="#Alquiler">
                  <img
                    src={Alquiler}
                    alt="Alquiler"
                    className="img-thumbnail mx-auto d-block iconItem"
                  />
                </a>
              </div>
              <div className="col-4 alquilerMovil">
                <a href="#AlquilerMovil">
                  <img
                    src={Alquiler}
                    alt="Alquiler"
                    className="img-thumbnail mx-auto d-block iconItem"
                  />
                </a>
              </div>
              <div className="col-4">
                <a href="#Servicios">
                  <img
                    src={Servicios}
                    alt="Servicios"
                    className="img-thumbnail mx-auto d-block iconItem"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 sinP" data-aos="fade-right">
            <img
              src={Somos}
              alt="Somos"
              className="img-thumbnail mx-auto d-block"
            />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 sinP">
            <img
              src={Arriba}
              alt="Arriba"
              className="img-thumbnail mx-auto d-block sinP"
            />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center seccionVideo">
          <div className="col-12 sinP">
            <img
              src={VideoImg}
              alt="Video"
              className="img-thumbnail mx-auto d-block sinP"
              // onClick={() => opens1()}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 sinP">
            <img
              src={Abajo}
              alt="Abajo"
              className="img-thumbnail mx-auto d-block sinP"
            />
          </div>
        </div>
      </div>
      {/*Seccion Eventos*/}
      <div className="container-fluid index1 seccionEventos" id="Eventos">
        <div className="row">
          <div className="col-12 col-md-6 sinP" data-aos="fade-right">
            <img
              src={EventoI}
              alt="Eventos"
              className="img-thumbnail d-block sinP"
            />
          </div>
          <div
            className="col-12 col-md-6 align-self-start"
            data-aos="fade-right"
          >
            <img
              src={EventosTitle}
              alt="Eventos"
              className="img-thumbnail d-block imgTitle imgCenter"
            />
            <p className="textosP">
              Organizamos y producimos todo tipo de eventos, nos diferenciamos
              por aportar valor añadido gracias a nuestra dilatada experiencia
              en el mundo de la animación escénica y los espectáculos.
            </p>
            <p className="textosP">
              Además, como empresa organizadora de eventos, contamos con una
              gran capacidad en cuanto a medios propios, tanto a nivel de
              equipos técnicos y materiales diversos, así como de personal
              humano y profesionales (técnicos o artistas) para la organización
              de eventos y producción de los mismo.
            </p>
            <br />
            <br />
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="row">
                  <div className="col-12">
                    <img
                      src={IconEvento}
                      alt="icon"
                      className="img-thumbnail mx-auto d-block"
                    />
                  </div>
                </div>
                <b>
                  <p className="titulosh">Producción del evento</p>
                </b>
                <p className="textosP">
                  Validadas las alternativas y recomendacion presentadas,
                  comenzamos a construir el evento tal y como los imaginaste
                  dotándolo de todos los medios necesario.
                </p>
              </div>
              <div className="col-12 col-md-4">
                <div className="row">
                  <div className="col-12">
                    <img
                      src={IconHumano}
                      alt="icon"
                      className="img-thumbnail mx-auto d-block"
                    />
                  </div>
                </div>
                <b>
                  <p className="titulosh">Talento humano</p>
                </b>
                <p className="textosP">
                  Ponemos a tu disposición a los mejores profesionales para
                  llevar a cabo la ejecución técnica de cualquier tipo de
                  efecto.
                </p>
              </div>

              <div className="col-12 col-md-4">
                <div className="row">
                  <div className="col-12">
                    <img
                      src={IconFiesta}
                      alt="icon"
                      className="img-thumbnail mx-auto d-block"
                    />
                  </div>
                </div>
                <b>
                  <p className="titulosh">Pantallas Led y equipos FX</p>
                </b>
                <p className="textosP">
                  En Dynamo contamos con equipos audiovisuales y de efectos
                  especiales que nos permiten vestir tu evento con efectos
                  únicos
                </p>
              </div>
            </div>
            <br />

            <div className="row">
              <div className="col-12 col-md-4">
                <ul className="listasD">
                  <li>Eventos corporativos</li>
                  <li>Ferias y congresos</li>
                  <li>Galas y presentaciones</li>
                </ul>
              </div>
              <div className="col-12 col-md-4">
                <ul className="listasD">
                  <li>Desfiles</li>
                  <li>Eventos deportivos</li>
                  <li>Eventos musicales</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Seccion Alquiler*/}
      <div className="container-fluid index1 alquilerEscritorio" id="Alquiler">
        <div className="row">
          <div className="col-12 col-md-6" data-aos="fade-right">
            <div className="row">
              <div className="col-12">
                <img
                  src={AlquilerTitle}
                  alt="Alquiler"
                  className="img-thumbnail d-block imgTitle imgAlquiler"
                />
              </div>
            </div>

            <p className="textosAlquiler">
              En Dynamo ofrecemos servicios de alquiler de mobiliario en los que
              reunimos todos los aspectos que deben reunir una gran fiesta o un
              gran evento, todos los detalles los pensamos por ti la decoración
              del mobiliario, la comida, el catering
            </p>
            <p className="textosAlquiler">
              Como empresa organizadora de toda clase de eventos y espectáculos,
              ofrecemos servicios de producción de efectos (FX) especiales para
              conciertos, festivales, fiestas, eventos corporativos u otros
              acontecimientos y celebraciones.
            </p>
            <br />
            <br />
            <div className="row">
              <div className="col-12 col-md-4">
                <p className="textosAlquilerlist sinM">Máquinas FX •</p>
                <p className="textosAlquilerlist sinM">
                  Sonido e iluminación •
                </p>
                <p className="textosAlquilerlist sinM">Cabinas de DJ •</p>
              </div>
              <div className="col-12 col-md-4">
                <p className="textosAlquilerlist sinM">Pantallas led •</p>
                <p className="textosAlquilerlist sinM">Atriles •</p>
                <p className="textosAlquilerlist sinM">
                  Tarimas y escenarios •
                </p>
              </div>
              <div className="col-12 col-md-4">
                <p className="textosAlquilerlist sinM">Truss y estructuras •</p>
                <p className="textosAlquilerlist sinM">Carpas y jaimas •</p>
                <p className="textosAlquilerlist sinM">Caballetes •</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 sinP" data-aos="fade-right">
            <img src={AlquilerI} alt="Eventos" className="imagenMedia" />
          </div>
        </div>
      </div>
      <br />
      <br />

      {/*Alquiler MOVIL*/}
      <div className="container-fluid index1 alquilerMovil" id="AlquilerMovil">
        <div className="row">
          <div className="col-12 col-md-6 sinP" data-aos="fade-right">
            <img src={AlquilerI} alt="Eventos" className="imagenMedia" />
          </div>
          <div className="col-12 col-md-6" data-aos="fade-right">
            <div className="row">
              <div className="col-12">
                <img
                  src={AlquilerTitle}
                  alt="Alquiler"
                  className="img-thumbnail d-block imgTitle imgCenter"
                />
              </div>
            </div>

            <p className="textosP">
              En Dynamo ofrecemos servicios de alquiler de mobiliario en los que
              reunimos todos los aspectos que deben reunir una gran fiesta o un
              gran evento, todos los detalles los pensamos por ti la decoración
              del mobiliario, la comida, el catering
            </p>
            <p className="textosP">
              Como empresa organizadora de toda clase de eventos y espectáculos,
              ofrecemos servicios de producción de efectos (FX) especiales para
              conciertos, festivales, fiestas, eventos corporativos u otros
              acontecimientos y celebraciones.
            </p>
            <br />
            <br />
            <div className="row">
              <div className="col-12 col-md-4">
                <ul className="listasD">
                  <li>Máquinas FX</li>
                  <li>Sonido e iluminación</li>
                  <li>Cabinas de DJ</li>
                </ul>
              </div>
              <div className="col-12 col-md-4">
                <ul className="listasD">
                  <li>Pantallas led</li>
                  <li>Atriles</li>
                  <li>Tarimas y escenarios</li>
                </ul>
              </div>
              <div className="col-12 col-md-4">
                <ul className="listasD">
                  <li>Truss y estructuras</li>
                  <li>Carpas y jaimas</li>
                  <li>Caballetes</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      {/*Seccion Servicios*/}
      <div className="container-fluid index1" id="Servicios">
        <div className="row">
          <div className="col-12 col-md-6 sinP" data-aos="fade-right">
            <img
              src={ServiciosI}
              alt="Servicios"
              className="img-thumbnail d-block sinP"
            />
          </div>
          <div
            className="col-12 col-md-6 align-self-start"
            data-aos="fade-right"
          >
            <img
              src={ServiciosTitle}
              alt="Eventos"
              className="img-thumbnail d-block imgTitle imgCenter"
            />
            <p className="textosP">
              Organizamos y producimos todo tipo de eventos, nos diferenciamos
              por aportar valor añadido gracias a nuestra dilatada experiencia
              en el mundo de la animación escénica y los espectáculos.
            </p>
            <p className="textosP">
              Además, como empresa organizadora de eventos, contamos con una
              gran capacidad en cuanto a medios propios, tanto a nivel de
              equipos técnicos y materiales diversos, así como de personal
              humano y profesionales (técnicos o artistas) para la organización
              de eventos y producción de los mismo.
            </p>
            <br />
            <br />
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="row">
                  <div className="col-12">
                    <img
                      src={IconEvento}
                      alt="icon"
                      className="img-thumbnail mx-auto d-block"
                    />
                  </div>
                </div>
                <b>
                  <p className="titulosh">Producción del evento</p>
                </b>
                <p className="textosP">
                  Validadas las alternativas y recomendacion presentadas,
                  comenzamos a construir el evento tal y como los imaginaste
                  dotándolo de todos los medios necesario.
                </p>
              </div>
              <div className="col-12 col-md-4">
                <div className="row">
                  <div className="col-12">
                    <img
                      src={IconHumano}
                      alt="icon"
                      className="img-thumbnail mx-auto d-block"
                    />
                  </div>
                </div>
                <b>
                  <p className="titulosh">Talento humano</p>
                </b>
                <p className="textosP">
                  Ponemos a tu disposición a los mejores profesionales para
                  llevar a cabo la ejecución técnica de cualquier tipo de
                  efecto.
                </p>
              </div>

              <div className="col-12 col-md-4">
                <div className="row">
                  <div className="col-12">
                    <img
                      src={IconFiesta}
                      alt="icon"
                      className="img-thumbnail mx-auto d-block"
                    />
                  </div>
                </div>
                <b>
                  <p className="titulosh">Pantallas Led y equipos FX</p>
                </b>
                <p className="textosP">
                  En Dynamo contamos con equipos audiovisuales y de efectos
                  especiales que nos permiten vestir tu evento con efectos
                  únicos.
                </p>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row justify-content-end">
          <div className="col-8 col-md-5 sinP">
            <img src={ImagenMedia} alt="Imgen" className="imagenMedia" />
          </div>
        </div>
      </div>

      {/* Galeria*/}
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 align-self-center sinP">
            <h2 className="galeriaT sinM">Nuestros Proyectos</h2>
          </div>
        </div>
      </div>
      <Galeria />
      <div className="container-fluid">
        <div className="row justify-content-center galeri2">
          <div className="col-12 align-self-center sinP"></div>
        </div>
      </div>

      {/*Seccion Contactenos */}
      <div className="container-fluid" id="Contactenos" data-aos="fade-right">
        <div className="row">
          <div className="col-12">
            <h2 className="contactenosT">Contáctenos</h2>

            <div className="row">
              <div className="col-12 formContacto">
                <Contacto />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row footer">
          <div className="col-12 col-md-6 align-self-center sinP">
            <img src={FooterImg} alt="Footer Img" className="footerImg" />
            <br />
            <br />
          </div>
          <div className="col-12 col-md-3">
            <p>
              <b>COLOMBIA</b>
            </p>
            <br />
            <p className="sinM">Barraquilla, Atlántico</p>
            <p className="sinM">Calle 80B No. 42b-96</p>
            <p className="sinM">Barrio Ciudad Jardín</p>
            <p className="sinM">
              <b>
                Tel. <br />
                (300) 310-5980 <br />
                (301) 457-7708 <br /> (300) 422-9391
              </b>
            </p>
            <br />
            <br />
          </div>
          <div className="col-12 col-md-3">
            <p>
              <b>SÍGUENOS</b>
            </p>
            <br />
            <img src={Instagram} alt="Instagram" className="redes" />
            <br />
            <br />
            <img src={Facebook} alt="Facebook" className="redes" />
            <br />
            <br />
            <br />
            <br />
          </div>
          <div className="row">
            <div className="col-12">
              <p className="copy">
                Copyright o Actualización 2021 DYNAMOPRO S.A. Derechos
                reservados.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal1 open1={open1} close1={close1} />
    </>
  );
}

export default withStyles(styles)(PageInicio);
