import { firestore } from "../firebase";

const authentication = {};

authentication.addContactenos = (formData) => {
  return new Promise((resolve, reject) => {
    if (!formData.name) {
      reject(new Error("No hay nombre"));

      return;
    }

    if (!formData.tel) {
      reject(new Error("No hay Telefono"));

      return;
    }

    if (!formData.emailForm) {
      reject(new Error("No hay correo"));

      return;
    }

    if (!formData.menssaje) {
      reject(new Error("No hay Mensaje"));

      return;
    }

    let batch = firestore.batch();

    const userDocumentReference = firestore.collection("usersClients").doc();

    batch.set(userDocumentReference, { ...formData, id: userDocumentReference.id });

    batch
      .commit()
      .then(() => {
        resolve("Contacto creado");
      })
      .catch((error) => {
        reject(new Error("No se pudo actualizar el Contacto"));
      });
  });
};

export default authentication;
