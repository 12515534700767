import React from "react";
import "./Register.css";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";
// MATERIAL ---------------------------------------------------------------->
import { Grid, TextField, Button, TextareaAutosize } from "@material-ui/core";
// <---------------------------------------------------------------- MATERIAL

// FORMIK ---------------------------------------------------------------->
import * as Yup from "yup";
import { useFormik } from "formik";
// <---------------------------------------------------------------- FORMIK
// SERVICES ---------------------------------------------------------------->
import { toast } from "react-toastify";
import authentication from "../../services/authentication";
import audio from "../../services/audio";
import { fieldValue } from "../../firebase";
// import listados from "../../services/listados";
//MULTIMEDIA

// <---------------------------------------------------------------- SERVICES

function Contactenos(props) {
  const { classes } = props;

  const formik = useFormik({
    initialValues: {
      name: "",
      tel: "",
      emailForm: "",
      menssaje: "",
      date: fieldValue.serverTimestamp(),
    },
    validationSchema: Yup.object({
      name: Yup.string().required("El nombre es obligatorio"),
      emailForm: Yup.string()
        .email("El correo electronico es invalido")
        .required("El email es obligatorio"),
      tel: Yup.number()
        .integer()
        .required("El número de celular es obligatorio")
        .min(10, "El número debe tener minimo 10 digitos"),
      //   menssaje: Yup.string().required("El mensaje es obligatorio"),
    }),
    onSubmit: async (formData) => {
      try {
        if (formData.menssaje.length === 0) {
          toast.error("El mensaje no puede estar vacio.");
        } else {
          if (formData.tel.length > 10 || formData.tel.length < 10) {
            toast.error("El número de teléfono debe tener 10 dígitos.");
          } else {
            authentication
              .addContactenos(formData)
              .then(() => {
                formik.resetForm();
                toast.success("Mensaje enviado.");
              })
              .catch((reason) => {
                const message = reason.message;
                toast.error(message);
              });
          }
        }
      } catch (error) {
        toast.error(error.message);
        console.log(error);
      }
    },
  });

  return (
    <>
      <div className={classes.gridForm}>
        <form
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          className={classes.form}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <p className="tCampos">
                Nombre completo<span className="requerido">: *</span>
              </p>
              <TextField
                className="inputRounded"
                size="small"
                autoComplete="fname"
                name="name"
                variant="outlined"
                required
                fullWidth
                // autoFocus
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={
                  formik.errors.name === undefined ||
                  formik.touched.name === undefined
                    ? false
                    : true
                }
                helperText={
                  formik.errors.name && formik.touched.name
                    ? formik.errors.name
                    : null
                }
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <p className="tCampos">
                Teléfono<span className="requerido">: *</span>
              </p>
              <TextField
                className="inputRounded"
                size="small"
                autoComplete="fname"
                name="tel"
                type="tel"
                variant="outlined"
                required
                fullWidth
                id="tel"
                // label="Celular"
                value={formik.values.tel}
                onChange={formik.handleChange}
                error={
                  formik.errors.tel === undefined ||
                  formik.touched.tel === undefined
                    ? false
                    : true
                }
                helperText={
                  formik.errors.tel && formik.touched.tel
                    ? formik.errors.tel
                    : null
                }
              />
            </Grid>
            <Grid item xs={12}>
              <p className="tCampos">
                Correo<span className="requerido">: *</span>
              </p>
              <TextField
                className="inputRounded"
                size="small"
                variant="outlined"
                required
                fullWidth
                id="emailForm"
                // label="Correo Eléctronico"
                name="emailForm"
                autoComplete="emailForm"
                value={formik.values.emailForm}
                onChange={formik.handleChange}
                error={
                  formik.errors.emailForm === undefined ||
                  formik.touched.emailForm === undefined
                    ? false
                    : true
                }
                helperText={
                  formik.errors.emailForm && formik.touched.emailForm
                    ? formik.errors.emailForm
                    : null
                }
              />
            </Grid>

            {/* <Grid item xs={12} sm={12} md={12}>
              <p className="tCampos">
                Mensaje<span className="requerido">: *</span>
              </p>
              <TextareaAutosize
                className="textarea"
                name="menssaje"
                required
                // autoFocus
                id="menssaje"
                value={formik.values.menssaje}
                onChange={formik.handleChange}
                error={
                  formik.errors.menssaje === undefined ||
                  formik.touched.menssaje === undefined
                    ? false
                    : true
                }
                helperText={
                  formik.errors.menssaje && formik.touched.menssaje
                    ? formik.errors.menssaje
                    : null
                }
              />
            </Grid> */}
            <Grid item xs={12} sm={12} md={12}>
              <p className="tCampos">
                Mensaje<span className="requerido">: *</span>
              </p>
              <TextareaAutosize
                aria-label="empty textarea"
                className="textarea"
                name="menssaje"
                required
                id="menssaje"
                value={formik.values.menssaje}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={() => audio.SoundBT()}
          >
            <b>ENVIAR</b>
          </Button>
        </form>
      </div>
    </>
  );
}
export default withStyles(styles)(Contactenos);
