import React from "react";
// Estilos
import "./Error.css";
//MULTIMEDIA
import Error404 from "../../assets/images/e404.png";

function Error() {

  return (
    <>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="logo_error">
              <img src={Error404} alt="Error 404" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Error;
