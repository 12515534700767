import React from "react";
import Gallery from "react-grid-gallery";
//Estilos
import "./PageInicio.css";
//Media
import Img1 from "../../assets/images/1.jpg";
import Img2 from "../../assets/images/2.jpg";
import Img3 from "../../assets/images/3.jpg";
import Img4 from "../../assets/images/4.jpg";
import Img5 from "../../assets/images/5.jpg";
import Img6 from "../../assets/images/6.jpg";
import Img7 from "../../assets/images/7.jpg";
import Img8 from "../../assets/images/8.jpg";
//Servicios

function GaleriaGrid() {
  const IMAGES = [
    {
      src: `${Img1}`,
      thumbnail: `${Img1}`,
      thumbnailWidth: 320,
      thumbnailHeight: 212,
      //isSelected: true,
      // caption: "After Rain (Jeshu John - designerspics.com)",
    },
    {
      src: `${Img2}`,
      thumbnail: `${Img2}`,
      thumbnailWidth: 320,
      thumbnailHeight: 212,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      //   { value: "People", title: "People" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
    },

    {
      src: `${Img3}`,
      thumbnail: `${Img3}`,
      thumbnailWidth: 320,
      thumbnailHeight: 212,
    },
    {
      src: `${Img4}`,
      thumbnail: `${Img4}`,
      thumbnailWidth: 320,
      thumbnailHeight: 212,
    },
    {
      src: `${Img5}`,
      thumbnail: `${Img5}`,
      thumbnailWidth: 320,
      thumbnailHeight: 212,
    },
    {
      src: `${Img6}`,
      thumbnail: `${Img6}`,
      thumbnailWidth: 320,
      thumbnailHeight: 212,
    },
    {
      src: `${Img7}`,
      thumbnail: `${Img7}`,
      thumbnailWidth: 320,
      thumbnailHeight: 212,
    },
    {
      src: `${Img8}`,
      thumbnail: `${Img8}`,
      thumbnailWidth: 320,
      thumbnailHeight: 212,
    },
  ];

  return (
    <>
      <div className="contaiener-fluid galeriageneral">
        <div className="row galeriaBk">
          <div className="col-12">
            <Gallery images={IMAGES} />
          </div>
        </div>
      </div>
    </>
  );
}

export default GaleriaGrid;
