import BtnAudio from "../assets/audio/boton.mp3";
import SlotAudio from "../assets/audio/payu.wav";
import RuedaAudio from "../assets/audio/rueda_1.mp3";
import ParoAudio from "../assets/audio/paro.wav";
import WinnerAudio from "../assets/audio/winner.mp3";
import LoserAudio from "../assets/audio/loser.wav";

const audio = {};

audio.SoundBT = () => {
  let soundBT = new Audio(BtnAudio);
  soundBT.play().catch((error) => {
    console.log(error);
  });
};

audio.SongSlot = () => {
  let songSlot = new Audio(SlotAudio);
  songSlot.play().catch((error) => {
    console.log(error);
  });
};

audio.SongRueda = () => {
  let songRueda = new Audio(RuedaAudio);
  songRueda.play().catch((error) => {
    console.log(error);
  });
};

audio.SongParo = () => {
  let songParo = new Audio(ParoAudio);
  songParo.play().catch((error) => {
    console.log(error);
  });
};

audio.SongWinner = () => {
  let songWinner = new Audio(WinnerAudio);
  songWinner.pause();
  songWinner.currentTime = 0;
  songWinner.play().catch((error) => {
    console.log(error);
  });
};

audio.SongLoser = () => {
  let songWLoser = new Audio(LoserAudio);
  songWLoser.pause();
  songWLoser.currentTime = 0;
  songWLoser.play().catch((error) => {
    console.log(error);
  });
};


export default audio;
