import React from "react";
// Estilos
import { Dialog, DialogContent } from "@material-ui/core";
import styles from "./styles";
import { withStyles } from "@material-ui/styles";
import "./PageInicio.css";
import ReactPlayer from 'react-player'
//MULTIMEDIA
import Videos1 from "../../assets/video/1.mp4";

function Modal1(props) {
  const { open1, close1 } = props;

  const body = (
    <>
      <DialogContent>
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-12 sinP">
              <ReactPlayer
                className="react-player playerMov"
                url={Videos1}
                width="100%"
                height="100%"
                controls={true}
                playing={open1}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </>
  );

  return (
    <>
      <Dialog
        open={open1}
        onClose={close1}
        keepMounted
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
      >
        {body}
      </Dialog>
    </>
  );
}

export default withStyles(styles)(Modal1);
